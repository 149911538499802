<template>
  <div class="fetch-loader">
    <div class="inside">
      <a-spin size="large"></a-spin>
      <h1 class="fetch-loader-description">Loading Incident...</h1>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const _ = require("lodash");
export default {
  created() {
    let vm = this;
    window.setTimeout(function () {
      vm.checkIfIncidentExistsOrFetch();
    }, 1000);
  },
  computed: {
    ...mapGetters("incidents", {
      isLoading: "isLoading",
      incidents: "incidents",
    }),

    tenantId() {
      return this.$route.params.tenantId;
    },

    incidentId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions("adminIncidentViewer", {
      reset: "reset",
      loadIncident: "loadIncident",
    }),

    checkIfIncidentExistsOrFetch() {
      if (this.isLoading) {
        // Try again in 2 seconds
        window.setTimeout(this.checkIfIncidentExistsOrFetch, 2000);
        return false;
      }
      let incident = this.getIncidentIfExists();
      if (incident) {
        this.$router.push(
          "/incidents/" + this.tenantId + "/" + this.incidentId + '?fromAdmin=true'
        );
      } else {
        this.attemptFetchIncident();
      }
    },

    getIncidentIfExists() {
      let incident = _.find(this.incidents, (incident) => {
        return incident.id === this.incidentId;
      });
      return incident;
    },

    attemptFetchIncident() {
      let vm = this;

      vm.reset();
      vm.loadIncident({
        tenantId: vm.tenantId,
        incidentId: vm.incidentId,
      })
        .then(() => {
          vm.$router.push("/incidents/" + vm.tenantId + "/" + vm.incidentId + '?admin=true');
        })
        .catch((e) => {
          vm.$message.error("Error loading incident");
          console.log(e);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.fetch-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  flex-direction: column;

  .inside {
    text-align: center;
  }

  .fetch-loader-description {
    font-size: 30px;
    margin-top: 25px;
    font-weight: 400;
  }
}
</style>